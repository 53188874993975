import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { CaretRight } from 'phosphor-react'
import PageTitle from '../components/Blocks/PageTitle'
import BreadCrumbs from '../components/Vehicles/Breadcrumbs'

const VehiclesLeaseTemplate = ({
  title,
  content,
  featuredImage,
  uri,
  wpChildren,
  page,
  parent,
}) => {
  let children = wpChildren

  if (wpChildren?.nodes.length === 0) {
    children = parent.wpChildren
  }

  let modelLinks =
    children.nodes.length &&
    children.nodes
      .filter(c => c.id !== page.id)
      .map(child => {
        return (
          <Link
            className="text-brand-blue uppercase my-2 flex items-center text-center justify-center md:justify-start"
            to={child.uri}
            key={child.id}
          >
            {child.title.replace('Leasing', '')}
            <CaretRight />
          </Link>
        )
      })

  return (
    <>
      <PageTitle title={title} />
      <div className="w-full h-full max-w-screen-2xl mx-auto px-8 md:px-20">
        <div className="flex w-full justify-between py-8 items-center flex-wrap gap-y-2">
          <BreadCrumbs page={page} parent={parent} />

          <a
              href="#quoteForm"
              class="btn md:ml-5 text-center w-full md:w-auto"
          >
            <>
              Get a quote
            </>
          </a>
        </div>
        <div className="flex w-full sm:gap-x-10 lg:gap-x-20 flex-wrap sm:flex-wrap lg:flex-nowrap">
          <div className="flex-shrink-0 flex flex-col w-full md:w-auto">
            <img
              src={featuredImage?.node?.mediaItemUrl}
              className="w-36 h-36 md:w-72 md:h-72 object-contain border border-gray-400"
            />
            {children?.nodes.length > 0 && (
              <div className="md:mt-20">{modelLinks}</div>
            )}
          </div>
          <div className="">
            <h2 className="font-light uppercase border-b border-brand-green py-10">
              {title.replace('Car', '')}
            </h2>
            <div
              className="transition-all py-10 vehicle-lease"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

VehiclesLeaseTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data, pageContext }) => {
  const { wpPage: page } = data
  
  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />
      <VehiclesLeaseTemplate
        title={page.title}
        content={page.content}
        featuredImage={page.featuredImage}
        uri={page.uri}
        wpChildren={page.wpChildren}
        page={{ ...page }}
        parent={{ ...data.parentPage }}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query VehiclesLeasePage($id: String!, $parentId: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      databaseId
      parentId
      parentDatabaseId
      seo {
        fullHead
        schema {
          raw
        }
      }
      uri
      wpChildren {
        nodes {
          uri
          ... on WpPage {
            id
            title
          }
        }
      }
      featuredImage {
        node {
          id
          mediaItemUrl
        }
      }
    }
    parentPage: wpPage(id: { eq: $parentId }) {
      title
      content
      databaseId
      parentId
      parentDatabaseId
      seo {
        fullHead
        schema {
          raw
        }
      }
      uri
      wpChildren {
        nodes {
          uri
          ... on WpPage {
            id
            title
          }
        }
      }
      featuredImage {
        node {
          id
          mediaItemUrl
        }
      }
    }
  }
`
