import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { CaretRight } from 'phosphor-react'

const BreadCrumbs = ({ page, parent }) => {
  return (
    <div className="flex items-center gap-2">
      <Link to="/vehicles/" className="text-sm text-gray-400 uppercase">
        All Vehicles
      </Link>
      <CaretRight className="text-gray-400" />

      {page.wpChildren.nodes.length === 0 && (
        <>
          <Link to={parent.uri} className="text-sm text-gray-400 uppercase">
            {parent.title}
          </Link>
          <CaretRight className="text-gray-400" />
        </>
      )}
      <Link to={page.uri} className="text-sm text-brand-blue uppercase">
        {page.title}
      </Link>
    </div>
  )
}

// <Link to={uri} className="text-sm text-brand-blue uppercase">
//   {title}
// </Link>

export default BreadCrumbs
